

















































import Vue from 'vue';

export default Vue.extend({
  name: 'SalaryValue',
  props: {
    contractType: String,
    currency: String,
    dailySalary: String,
    annualSalary: String
  },
  data() {
    return {
      annualySalaryContracts: ['Full-time', 'Part-time']
    };
  }
});
